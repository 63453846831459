@font-face {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Inconsolata-latin.woff2') format('woff2');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/AvenirLTStd-Book.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/AvenirLTStd-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/AvenirLTStd-Heavy.woff') format('woff');
}
